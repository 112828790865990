window.setTimeout(function() {
  const spacer = 24;

  /**
   * Nav and nav drawer
   */
  const nav = document.querySelector('.dropdown-nav');
  const navItems = document.querySelectorAll('.dropdown-nav > li');
  const navHover = document.querySelector('.nav-hover');
  const navDrawer = document.querySelector('#nav-drawer');
  const navArrow = document.querySelector('.nav-arrow');
  const drawers = document.querySelectorAll('.drawer-container');
  const closeButton = document.querySelector('#nav-drawer > button');
  const navDrawerHeight = Math.max(...Array.from(drawers).map(drawer => drawer.offsetHeight));
  navDrawer.setAttribute('style', `height: ${navDrawerHeight}px`);

  closeButton.addEventListener('click', function() {
    navDrawer.classList.remove('opened');
  })

  for (let item of navItems) {
    let left = item.offsetLeft;
    let width = item.offsetWidth;
    let drawerId = item.getAttribute('id');
    let activeDrawer = document.getElementById(`${drawerId}-drawer`)

    item.addEventListener('mouseenter', function() {
      navHover.setAttribute('style', `left: ${left + 8}px; width: ${width - 16}px`);
      navArrow.setAttribute('style', `left: ${width/2 + left}px`);
      activeDrawer.classList.add('active-drawer');

      for (let drawer of drawers) {
        if (drawer !== activeDrawer) {
          drawer.classList.remove('active-drawer');
        }
      }
    });
  }

  nav.addEventListener('mouseenter', function() {
    navDrawer.classList.add('opened');
  })

  nav.addEventListener('mouseleave', function() {
    navHover.addEventListener('transitionend', function x () {
      navHover.setAttribute('style', 'left: unset; width: unset');
      navHover.removeEventListener('transitionend', x);
    })
    navArrow.addEventListener('transitionend', function y () {
      navArrow.setAttribute('style', 'left: unset; top: -1000px');
      navArrow.removeEventListener('transitionend', y);
    })
  })

  /**
   * Products
   */
  const productsList = document.querySelector('.products-list');
  const productsListItems = productsList.querySelectorAll('li');
  const mainItems = Array.from(productsListItems).filter(item => item.querySelector('a'));
  const subItems = Array.from (productsListItems).filter(item => !item.querySelector('a'));
  const panels = document.querySelectorAll('.panel');

  for (const item of mainItems) {
    const top = item.offsetTop;
    const height = item.querySelector('a') ? item.querySelector('a').offsetHeight : 0;
    const panelID = item.getAttribute('data-panel');
    const activePanel = document.getElementById(panelID);

    item.querySelector('a').addEventListener('mouseenter', function() {
      productsList.setAttribute('style', `
        --hover-y-position: ${top}px;
        --hover-height: ${height}px
      `);

      for (const panel of panels) {
        if (panel !== activePanel) {
          panel.classList.remove('visible');
        }
      }
      activePanel.classList.add('visible');
    });
  }

  for (const item of subItems) {
    const panelID = item.getAttribute('data-panel');
    const activePanel = document.getElementById(panelID);

    item.addEventListener('mouseenter', function() {
      for (const panel of panels) {
        if (panel !== activePanel) {
          panel.classList.remove('visible');
        }
      }
      activePanel.classList.add('visible');
    });
  }

  /**
   * Solutions
   */
  let solutionsLists = document.querySelectorAll('.solutions-list');
  for (let list of solutionsLists) {
    let listItems = list.querySelectorAll('li');
    for (let item of listItems) {
      let top = item.offsetTop;
      let height = item.offsetHeight;
      let left = item.offsetLeft;
      let width = item.offsetWidth;
      let link = item.querySelector('a')

      link.addEventListener('mouseenter', function() {
        list.setAttribute('style', `
          --hover-top: ${top}px;
          --hover-height: ${height}px;
          --hover-left: ${left}px;
          --hover-width: ${width}px;
          --opacity: 1;
        `);
      });
    }
    list.addEventListener('mouseleave', function() {
      list.style.setProperty('--opacity', 0);
    })
  }

  /**
   * Learn
   */
  let learnList = document.querySelector('#learn-drawer');
  let learnListItems = learnList.querySelectorAll('.hover-link');
  for (let item of learnListItems) {
    item.addEventListener('mouseenter', function() {
      learnList.setAttribute('style', `
        --hover-top: ${item.offsetTop}px;
        --hover-height: ${item.offsetHeight}px;
        --hover-left: ${item.offsetLeft}px;
        --hover-width: ${item.offsetWidth + spacer * 1.75}px;
        --opacity: 1;
      `);
      item.parentElement.nodeName === 'LI'
        ? learnList.classList.add('saturated')
        : learnList.classList.remove('saturated')
    });
    item.addEventListener('mouseleave', function() {
      learnList.style.setProperty('--opacity', 0);
    });
  }

  /**
   * Company
   */
  let companyList = document.querySelector('#company-drawer');
  let companyListItems = companyList.querySelectorAll('.hover-link');
  for (let item of companyListItems) {
    item.addEventListener('mouseenter', function() {
      companyList.setAttribute('style', `
        --hover-top: ${item.offsetTop}px;
        --hover-height: ${item.offsetHeight}px;
        --hover-left: ${item.offsetLeft}px;
        --hover-width: ${item.offsetWidth + spacer * 1.75}px;
        --opacity: 1;
      `);
      item.parentElement.nodeName === 'LI'
        ? companyList.classList.add('saturated')
        : companyList.classList.remove('saturated')
    });
    item.addEventListener('mouseleave', function() {
      companyList.style.setProperty('--opacity', 0);
    })
  }

}, 1000)
